import React from "react"
import styled from "styled-components"
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

import Seo from "../components/Seo"
import PageTitle from '../components/PageTitle'
import NewsletterCTA from '../components/NewsletterCTA'

const HeroSection = styled.section`
  .newsletter-hero-image {
    width: 100%;

    .gatsby-image-wrapper {
      width: 100%;
      height: auto;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;
        background-image: linear-gradient(225deg, #000000 0%, rgba(0,0,0,0.00) 47%, #000000 100%);
      }
    }
  }
`

const NewslettersSection = styled.section`
  margin-bottom: 100px;
  margin-top: -20px;

  @media(min-width: 768px) {
    margin-top: -80px;
  }

  .newsletters-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    column-gap: 80px;
    row-gap: 30px;
    margin-bottom: 100px;

    .newsletter {
      position: relative;

      .newsletter-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        text-align: center;

        p {
          font-size: 22px;
          text-transform: uppercase;
          margin-bottom: 30px;
        }

        a {
          font-size: 19px;
          color: var(--white);
          text-decoration: underline;
          text-decoration-color: var(--pink);
        }
      }
    }
  }

  .newsletter-contact {
    text-align: center;
  }
`

const NewslettersPage = (props) => {

  return (
    <main>
      {props.data.newsletterPageData.edges.map(({ node }) => (
        <div key={node.id}>
          <PageTitle title={node.pageTitle} paragraph={node.pageTitleParagraph.pageTitleParagraph} />
          <HeroSection>
            <div className="newsletter-hero-image">
              <GatsbyImage image={node.heroImage.gatsbyImageData} alt={node.heroImage.title} />
            </div>
          </HeroSection>
        </div>
      ))}
      <NewslettersSection>
        <div className="container">
          <div className="newsletters-grid">
            {props.data.newsletters.edges.map(({ node }) => (
              <div className="newsletter" key={node.id}>
                <StaticImage
                  src="../images/home-services-frame-graphic.png"
                  width={240}
                  height={240}
                  alt=""
                />
                <div className="newsletter-content">
                  <p>{node.title}</p>
                  <a href={node.newsletterPdf.url} target="_blank" rel="noopener noreferrer">Download Issue</a>
                </div>
              </div>
            ))}
          </div>
        </div>
        {props.data.newsletterPageData.edges.map(({ node }) => (
          <div className="newsletter-contact" key={node.id}>
            {/* <Link to={node.contactButtonUrl} className="btn btn-large">{node.contactButtonText}</Link> */}
            <a href={node.contactButtonUrl} className="btn btn-large">{node.contactButtonText}</a>
          </div>
        ))}
      </NewslettersSection>
      <NewsletterCTA />
    </main>
  )
}

export default NewslettersPage

export const Head = (seo) => {
  const seoData = seo.data.newsletterPageData
  return (
    <>
      {seoData.edges.map(({ node }) => (
      <Seo key={node.id} title={node.seoTitle} description={node.seoDescription.seoDescription} image={node.seoImage.url} />
      ))}
    </>
  )
}

export const NewsletterContent = graphql`
  {
    newsletterPageData: allContentfulNewsletterPage {
      edges {
        node {
          id
          pageTitle
          pageTitleParagraph {
            pageTitleParagraph
          }
          heroImage {
            gatsbyImageData(width: 1920, placeholder: BLURRED)
            title
          }
          contactButtonText
          contactButtonUrl
          seoTitle
          seoDescription {
            seoDescription
          }
          seoImage {
            url
          }
        }
      }
    }
    newsletters: allContentfulNewsletters {
      edges {
        node {
          id
          title
          newsletterPdf {
            url
          }
        }
      }
    }
  }
`;
